<template>
    <div class=" mx-auto p-5 dark:bg-gray-900 bg-white rounded w-full  ">
        <div class="text-white  flex mb-3">
            <button v-show="!edit" class=" bg-gray-700 py-2 px-3 rounded mx-1" @click="$router.back()">
                <i class="fas fa-arrow-left"></i> Назад
            </button>
            <button id="edit" class=" bg-blue-600 py-2 px-3 rounded mx-1  " @click="edit = !edit"
                    v-show="!edit ">
                <i class="fas fa-edit"></i> Редактировать
            </button>
            <button id="save"
                    class=" bg-green-600 py-2 px-3 rounded mx-1  dark:text-white  text-black"
                    v-show="edit" @click="save">
                <i class="fas fa-save"></i> Сохранить
            </button>
            <button id="notSave"
                    class=" bg-red-600 py-2 px-3 rounded mx-1 dark:text-white text-black "
                    v-show="edit" @click="notSave">
                <i class="fas fa-times"></i> Не сохранять
            </button>
        </div>

        <div class="border  rounded dark:border-gray-700 border-gray-300 mb-3" v-for="group in groups"
             :key="group.name">
            <div class="dark:bg-gray-700 bg-gray-300 text-md px-3 py-2">
                <h3>{{ group.name }}</h3>
            </div>

            <div class="flex px-3 my-1" v-for="field in group.fields" :key="field.code">
                <div class="w-1/2 text-right px-3  font-semibold dark:text-gray-200 text-black">
                    {{ field.name }}
                </div>
                <input v-model="form[field.code]" v-if="group.name !== 'Подтверждающие документы' "
                       class="w-1/2 text-left px-3 dark:text-gray-200 text-black  outline-none"
                       :class="{'dark:bg-gray-800 bg-gray-200':edit, 'dark:bg-gray-900 bg-white':!edit}"
                       :disabled="!edit">
                <a v-else :href="form[field.code]" target="_blank"
                   class="flex block items-center dark:text-gray-200 text-black text-white"><i
                    class=" far fa-file text-4xl"></i> <span class="px-2">{{
                        form[field.code] | getFilename
                    }}</span></a>
            </div>

        </div>

        <div class="border  rounded dark:border-gray-700 border-gray-300 mb-3 dark:text-white " v-if="active > 2">
            <div class="dark:bg-gray-700 bg-gray-300 text-md px-3 py-2">
                <h3>Подтверждающие документы</h3>
            </div>
            <div class="flex px-3 my-1  dark:text-gray-200 text-black">
                <div class="w-1/2 text-right px-3  font-semibold dark:text-gray-200 text-black">
                    Сертификат регистрации
                </div>
                <a :href="form.uf_reg_sertificate" target="_blank" v-if="form.uf_reg_sertificate"
                   class="flex block items-center "><i
                    class=" far fa-file text-3xl"></i>
                    <span class="px-2">{{ form.uf_reg_sertificate | getFilename }}</span>

                </a>
                <span class="px-2 dark:text-red text-red-600" v-else>Регистрация отсутствует</span>
            </div>
            <div class="flex px-3 my-1">
                <div class="w-1/2 text-right px-3  font-semibold dark:text-gray-200 text-black">
                    Подтверждение полномочий
                </div>
                <a :href="form.uf_credentials" target="_blank" v-if="form.uf_credentials"
                   class="flex block items-center dark:text-gray-200 text-black "><i
                    class=" far fa-file text-3xl"></i>
                    <span class="px-2">{{ form.uf_credentials | getFilename }}</span>

                </a>
                <span class="px-2 dark:text-red text-red-600" v-else>Документ отсутствует</span>
            </div>

        </div>


        <div class="rounded" v-if="reject">
            <div class="dark:bg-red-700 bg-red-300 text-md px-3 py-2">
                <h3>Введите причину отказа</h3>
            </div>
            <textarea class="dark:bg-gray-800 bg-gray-100 w-full outline-none p-5"
                      v-model="reason"></textarea>
        </div>



    </div>


</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UserEdit",
    data: () => ({
        id: null,
        form: {},
        edit: false,
        reject: false,
        reason: '',
        groups: [
            {
                name: 'Юридическое лицо',
                fields: [
                    {name: 'Наименование', code: 'uf_name'},
                    {name: 'Юридический адрес', code: 'uf_adress'},
                    {name: 'Адрес для корреспонденции', code: 'uf_ur_korp_adress'},
                    {name: 'Страна регистрации', code: 'uf_org_country'},
                    {name: 'УНП', code: 'uf_unp'},
                    {name: 'E-mail для корреспонденции', code: 'uf_org_email'},
                    {name: 'Телефон / факс', code: 'uf_org_phone'}
                ]
            },
            {
                name: 'Контактное лицо',
                fields: [
                    {name: 'Фамилия', code: 'uf_contact_surname'},
                    {name: 'Имя', code: 'uf_contact_name'},
                    {name: 'Отчество', code: 'uf_contact_father'},
                    {name: 'Должность', code: 'uf_contact_position'},
                    {name: 'E-mail', code: 'uf_contact_email'},
                    {name: 'Телефон', code: 'uf_contact_phone'},
                ]
            },
            {
                name: 'Банковские реквизиты',
                fields: [
                    {name: 'Расчетный счет', code: 'uf_rs'},
                    {name: 'Наименование банка', code: 'uf_bank'},
                    {name: 'Адрес банка', code: 'uf_bank_address'},
                    {name: 'БИК SWIFT', code: 'uf_bik_swift'}
                ]
            },

        ]
    }),
    computed: {
        ...mapGetters({
            active: 'admin/group'
        })
    },
    created() {
        this.id = this.$route.params.id
        this.fetchInfo()
    },
    // props: {
    //     id: {
    //         required: true,
    //     }
    // },
    methods: {
        async accept(uid) {

            let status = 0
            switch (this.active) {
                case 1:
                    status = 2;
                    break
                case 3:
                    status = 4;
                    break
            }
            await this.$store.dispatch('admin/updateContractStatus', {uid: uid, status: status})
            this.$emit('close')
        },
        async commitReject(uid) {
            let status = 0
            switch (this.active) {
                case 1:
                    status = 0;
                    break
                case 3:
                    status = 2;
                    break
            }
            this.reject = false
            await this.$store.dispatch('admin/updateContractStatus', {uid: uid, status: status, reason: this.reason})
            this.$emit('close')

        },
        async save() {
            let body = JSON.parse(JSON.stringify(this.form))

            body.email = btoa(body.email)
            body.login = btoa(body.login)
            body.uf_contact_email = btoa(body.uf_contact_email)
            body.uf_org_email = btoa(body.uf_org_email)

            await this.$store.dispatch('admin/updateContract', {uid: this.form.id, body: body})
            await this.fetchInfo()
            this.edit = false
        },
        notSave() {
            this.fetchInfo()
            this.edit = false
        },
        async fetchInfo() {
            let res = await this.$store.dispatch('admin/getContractSingle', {id: this.id})
            this.form = res.data.data
        }
    },
    filters: {
        getFilename(link) {
            if (link) {
                let array = link.split('/')
                return array[array.length - 1]
            }
        }
    }
}
</script>

<style scoped>

.modal-wrapper::-webkit-scrollbar {
    display: none;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
